<template>
  <auth>
    <a-row type="flex" justify="center" class="full_height bg" align="middle">
      <a-col :span="12" class="height-100">
        <h2 class="height-100">
          Un email de confirmation vous a été envoyé, veuillez vérifier
          également votre dossier « Courrier indésirable » et cliquer sur le
          lien transmis pour vous connecter
        </h2>
      </a-col>
    </a-row>
  </auth>
</template>

<script>
import Auth from "@/components/common/AuthParent";
export default {
  name: "RegistrationSuccess",
  components: {
    Auth,
  },
};
</script>

<style scoped>
.full_height {
  height: 100%;
}
h2.height-100 {
  border: solid rgba(255, 255, 255, 0.21);
  margin: 3%;
  text-align: center;
  color: #009688;
  background-color: rgba(255, 255, 255, 0.63);
}
</style>
