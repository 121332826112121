var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth",
    [
      _c(
        "a-row",
        {
          staticClass: "full_height bg",
          attrs: { type: "flex", justify: "center", align: "middle" }
        },
        [
          _c("a-col", { staticClass: "height-100", attrs: { span: 12 } }, [
            _c("h2", { staticClass: "height-100" }, [
              _vm._v(
                "\n        Un email de confirmation vous a été envoyé, veuillez vérifier\n        également votre dossier « Courrier indésirable » et cliquer sur le\n        lien transmis pour vous connecter\n      "
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }